import React from "react";
import HomeCountUpComponent from "../../components/page-wise-components/home/HomeCountUpComponent";
import TopRatedCoursesComponent from "../../components/page-wise-components/home/TopRatedCoursesComponent";
// import TopDepartmentComponent from "../../components/page-wise-components/home/TopDepartmentComponent";
// import FeaturedTopicComponent from "../../components/page-wise-components/home/FeaturedTopicComponent";
import BecomeWithComponent from "../../components/page-wise-components/home/BecomeWithComponent";
import FeatureSectionComponent from "../../components/page-wise-components/home/FeatureSectionComponent";
import ThreeBoxLayout from "../../components/core/three-box-layout/ThreeBoxLayout";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import MobileAppSection from "../../components/page-wise-components/home/MobileAppSection/MobileAppSection";
import Loading from "../../components/global/Loading";
import MyCarousel from "./Carousel/Carousel";
import Leading from "../RequestDemo/Leading";
import NewFeaturedTopics from "../../components/page-wise-components/home/NewFeaturedTopics";
import NewTopDepartment from "../../components/page-wise-components/home/NewTopDepartment";

const getFeaturedListData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "for-enterprise/featured-list?pageName=home",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getCounterData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "home/counter", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getOurDepartmentData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "home/our-departments",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getTopDepartmentData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "home/get-top-departments",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getFeaturedTopicData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "home/get-featured-topics",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

// const getComputerScreenData = async ({ signal }) => {
// try {
//     const response = await axios.get(
//       window.apiBaseUrl + "home/complete-mobile-web-solutions",{
//         signal, // Pass the signal to axios
//       }
//     );
//     return response.data.data;
//   };
// } catch (error) {
//   if (axios.isCancel(error)) {
//     console.log("Request canceled:", error.message);
//   } else {
//     throw error; // Re-throw if not canceled
//   }
// }

const getBecomeWithData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + "home/what-you-can-become-with-matriye",
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getDownloadAppData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "home/download-apps", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getFeaturedInData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "home/featured-in", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getPlacementPartnerData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "placement-partner", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

const getMetaData = async ({ signal }) => {
  try {
    const response = await axios.get(window.apiBaseUrl + "home/meta-details", {
      signal, // Pass the signal to axios
    });
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

//  Leading Global
const getLeadingGlobalData = async ({ signal }) => {
  try {
    const response = await axios.get(
      window.apiBaseUrl + `home/testimonials?featurePage=home`,
      {
        signal, // Pass the signal to axios
      }
    );
    return response.data.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      throw error; // Re-throw if not canceled
    }
  }
};

// const getHeaderData = async ({ signal }) => {
try {
} catch (error) {
  if (axios.isCancel(error)) {
    console.log("Request canceled:", error.message);
  } else {
    throw error; // Re-throw if not canceled
  }
}
//   const response = await axios.get(
//     window.apiBaseUrl + `request-demo?featureName=home`
//   );
//   return response.data.data;
// };

const Home = () => {
  const { data: featuredListData, isLoading: featuredListLoading } = useQuery(
    "homeFeaturedList",
    getFeaturedListData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: meta } = useQuery("metaData", getMetaData);
  const { data: counterData, isLoading: counterLoading } = useQuery(
    "counterData",
    getCounterData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: ourDepartmentData, isLoading: ourDepartmentLoading } = useQuery(
    "ourDepartments",
    getOurDepartmentData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: topDepartmentData, isLoading: topDepartmentLoading } = useQuery(
    "topDepartment",
    getTopDepartmentData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: featuredTopicData, isLoading: featuredTopicLoading } = useQuery(
    "featuredTopic",
    getFeaturedTopicData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: downloadAppData, isLoading: downloadAppLoading } = useQuery(
    "downloadApp",
    getDownloadAppData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  // const { data: computerScreen } = useQuery(
  //   "computerScreen",
  //   getComputerScreenData
  // );
  const { data: whatCanBecomeWithData, isLoading: whatCanBecomeWithLoading } =
    useQuery("canBecomeWith", getBecomeWithData, {
      cacheTime: 0, // Disable caching for this query
    });
  // const { data: downloadApp } = useQuery("downloadApp", getDownloadAppData);
  const { data: featuredInData, isLoading: featuredInLoading } = useQuery(
    "featuredIn",
    getFeaturedInData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: placementPartnerData } = useQuery(
    "placementPartner",
    getPlacementPartnerData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const metaData = meta;

  const { data: leadingData, isLoading: leadingLoading } = useQuery(
    "getLeadingData",
    getLeadingGlobalData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  // const { data: headerData, isLoading: headerLoading } = useQuery(
  //   "headerData",
  //   getHeaderData
  // );

  if (
    featuredListLoading ||
    counterLoading ||
    ourDepartmentLoading ||
    topDepartmentLoading ||
    featuredTopicLoading ||
    whatCanBecomeWithLoading ||
    featuredInLoading ||
    leadingLoading ||
    downloadAppLoading
    // headerLoading
  )
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {featuredListData ? <MyCarousel items={featuredListData} /> : ""}
      {counterData ? <HomeCountUpComponent data={counterData} /> : ""}

      {placementPartnerData ? (
        <FeatureSectionComponent
          data="Collaborating with the world's top universities and leading companies"
          dataList={placementPartnerData}
          isPlacement="true"
        />
      ) : (
        ""
      )}

      {ourDepartmentData ? (
        <ThreeBoxLayout
          backgroundImage="https://www.matriyeacademy.com/website-assets/images/home-our-department-bg.webp"
          pageTitle={"Our Departments"}
          pageSubTitle={"Learn In-demand Skills"}
          boxContent={ourDepartmentData}
          customId="ourDepartment"
        />
      ) : (
        ""
      )}
      <TopRatedCoursesComponent />
      {topDepartmentData ? <NewTopDepartment data={topDepartmentData} /> : ""}
      {featuredTopicData ? <NewFeaturedTopics data={featuredTopicData} /> : ""}
      {downloadAppData ? <MobileAppSection data={downloadAppData} /> : ""}
      {whatCanBecomeWithData ? (
        <BecomeWithComponent
          data={whatCanBecomeWithData.whatYouCanBecomeHeading}
          dataList={whatCanBecomeWithData.positionList}
        />
      ) : (
        ""
      )}
      {featuredInData ? (
        <FeatureSectionComponent
          data={featuredInData.featuredInHeading}
          dataList={featuredInData.featureList}
        />
      ) : (
        ""
      )}
      {leadingData ? (
        <Leading
          HeaderData={leadingData.staticData}
          LeadingData={leadingData.list}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
